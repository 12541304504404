import React, { useEffect, useState, useCallback } from "react";
import { ModuleWrapper } from "../moduleWrapper";
import useEmblaCarousel from "embla-carousel-react";
import { SanityImage } from "@components/sanity/sanityImage";
import cx from "classnames";
import { motion } from "framer-motion";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import ChevronPrev from "@svg/chevron-prev.svg";
import ChevronNext from "@svg/chevron-next.svg";

const variants = {
  inactive: {
    opacity: 0.2,
  },
  active: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const PrevButton = ({ enabled, onClick }) => (
  <button
    className={cx(
      "transition-all ease-in-out duration-300  w-12 h-12 rounded-full cursor-pointer touch-manipulation bg-red bg-opacity-10  ",
      {
        "opacity-20": !enabled,
      }
    )}
    onClick={onClick}
    disabled={!enabled}
  >
    <ChevronPrev className="m-auto" />
  </button>
);

const NextButton = ({ enabled, onClick }) => (
  <button
    className={cx(
      " transition-all ease-in-out duration-300  w-12 h-12 rounded-full cursor-pointer touch-manipulation bg-red bg-opacity-10 ",
      {
        "opacity-20": !enabled,
      }
    )}
    onClick={onClick}
    disabled={!enabled}
  >
    <ChevronNext className="m-auto" />
  </button>
);

const GraphCarousel = ({ config, title, subtitles, description, graphs }) => {
  const breakpoints = useBreakpoint();
  const [viewportRef, embla] = useEmblaCarousel({
    loop: false,
    // containScroll: true,
    // dragFree: true,
    startIndex: 0,
  });

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const [activeIndex, setActiveIndex] = useState(0);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
    setActiveIndex(embla.selectedScrollSnap());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();

    embla.on("select", onSelect);
  }, [embla, onSelect]);

  return (
    <ModuleWrapper {...config}>
      <div
        className="text-center mx-auto px-gutter"
        style={{ maxWidth: breakpoints.md ? "75%" : "100%" }}
      >
        <h2 className="text-5xl text-red mb-6">{title}</h2>
        <div className="flex text-dark-grey justify-center space-x-10">
          {subtitles?.map((subtitle, index) => (
            <h3>{subtitle}</h3>
          ))}
        </div>

        {description && (
          <h3 className="text-base text-dark-grey">{description}</h3>
        )}
      </div>
      <div className="mt-16 relative w-full">
        <div ref={viewportRef} className="overflow-hidden">
          <div className="flex gap-x-12 items-center">
            {/* <div className="h-auto" style={{ width: "762px" }} /> */}
            {graphs.map((graph, index) => {
              return (
                <div
                  style={{
                    flex: "0 0 auto",
                    width: breakpoints.md ? "50%" : "80%",
                  }}
                >
                  <motion.div
                    variants={variants}
                    initial="inactive"
                    animate={activeIndex === index ? "active" : "inactive"}
                    className={cx(" relative rounded-2xl w-full", {})}
                    key={index}
                  >
                    <SanityImage image={graph} className="w-full " />
                  </motion.div>
                </div>
              );
            })}
            {/* <div className="h-auto" style={{ width: "762px" }} /> */}
          </div>
        </div>
        <div className=" mt-10 z-50 w-3/4 flex justify-between absolute bottom-1/2 right-1/2 transform translate-y-1/2 translate-x-1/2">
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
      </div>
    </ModuleWrapper>
  );
};

export default GraphCarousel;
